@use '@angular/material' as mat;
@import "../node_modules/bootstrap/scss/bootstrap.scss";

@include mat.core();

$custom-typography: mat.define-typography-config($font-family: 'Inter',
  );

$custom-theme: mat.define-light-theme(( //    color: (
      //      primary: $kids-primary,
      //      accent: $kids-accent,
      //    ),
      typography: $custom-typography,
    ));

@include mat.all-component-themes($custom-theme);
@include mat.typography-hierarchy($custom-typography);

html,
body {
  height: 100%;
}

body {
  margin: 0;
}

:root {
  // --primaryColor: #d43877;
  // --textPrimaryColor: white;
  // --hoverPrimaryColor: #ffc9df;
  // --hoverTextPrimaryColor: black;
  // --headerColor: #f0f0f0;
  // --headerTextColor: black;
  // --sidebarColor: white;
  // --sidebarTextColor: black;
  // --sidebarItemSelectedColor: #ffc9df;
  // --sidebarItemTextSelectedColor: black;
  // --sidebarItemHoverColor: #ffc9df;
  // --sidebarItemTextHoverColor: black;

  // SFBM
  // --primaryColor: #0999e0;
  // --textPrimaryColor: white;
  // --hoverPrimaryColor: #beeaf3;
  // --hoverTextPrimaryColor: black;
  // --headerColor: #f0f0f0;
  // --headerTextColor: black;
  // --sidebarColor: white;
  // --sidebarTextColor: black;
  // --sidebarItemSelectedColor: #beeaf3;
  // --sidebarItemTextSelectedColor: black;
  // --sidebarItemHoverColor: #beeaf3;
  // --sidebarItemTextHoverColor: black;

  --mdc-theme-primary: var(--primaryColor);
}

@font-face {
  font-family: 'Inter';
  src: url('assets/Inter-Regular.ttf');
}

@font-face {
  font-family: 'Inter-Bold';
  src: url('assets/Inter-Bold.ttf');
}

app-footer {
  position: inherit;
}

// Container
.mat-drawer-content {
  overflow: hidden !important;
}

.mini-sidenav-menu {

  .mat-drawer-inner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.sidenav-menu {

  .mat-drawer-inner-container {
    display: flex;
    flex-direction: column;
  }
}

// Menu
.app-menu {
  padding: 8px;
  // min-width: 200px !important;

  .mat-mdc-menu-content {
    padding: 0 !important;
  }

  .mat-mdc-menu-item {
    border-radius: 4px;
    padding: 8px;
    min-height: 40px !important;
    --mat-menu-item-label-text-size: 12px !important;

    &:hover {
      background-color: var(--hoverPrimaryColor) !important;

      span {
        color: var(--hoverTextPrimaryColor);
      }

      mat-icon {
        color: var(--hoverTextPrimaryColor);
      }
    }
  }

  .account-menu-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100px;
    min-width: 200px;
    border-bottom: 1px solid #f0f0f0;
    cursor: pointer;

    &:hover {
      background-color: var(--hoverPrimaryColor);

      span {
        color: var(--hoverTextPrimaryColor);
      }

      mat-icon {
        color: var(--hoverTextPrimaryColor);
      }

    }

    mat-icon {
      font-size: 38px;
      height: 38px;
      width: 38px;
    }
  }
}

// App Snackbar
.app-snackbar {
    // --mdc-snackbar-container-color: var(--primaryColor) !important;
    // --mdc-snackbar-supporting-text-color: var(--textPrimaryColor) !important;
    // --mat-snack-bar-button-color: var(--textPrimaryColor) !important;
    // --mdc-snackbar-container-shape: 10px !important;
    --mdc-snackbar-container-color: white !important;
    --mdc-snackbar-supporting-text-color: #181818 !important;
    --mat-snack-bar-button-color: var(--textPrimaryColor) !important;
    --mdc-snackbar-supporting-text-size: 10px !important;
    --mdc-snackbar-supporting-text-line-height: normal !important;
    --mdc-snackbar-container-shape: 10px !important;

  .icon-column {
    width: 36px;
    max-width: 36px;
    padding: 0;
    display: flex;
    // align-items: center;
    color: var(--primaryColor);
  }

  .text-column {
    max-width: 366px;
  }

  .title {
    color: var(--primaryColor);
    font-weight: bold;
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.mat-mdc-snack-bar-container {
  top: 60px;
  right: 24px !important;
  margin: 0 !important;
  left: auto !important;
  width: fit-content;
  height: fit-content;
  position: fixed !important;
  // margin-top: 60px !important;
  // margin-right: 24px !important;
}

.mdc-snackbar__label {
  padding: 24px !important;
}

.mdc-snackbar__surface {
  max-width: 450px !important;
  min-width: 250px !important;
  padding-right: 0 !important;
  box-shadow: 0 2px 4px 0 rgba(190, 190, 190, 0.5) !important;
}


// Calendar
.mat-calendar-body-selected {
  background-color: var(--primaryColor) !important;
}

// Mat form field
mat-form-field {
  .mdc-text-field {
    // background-color: red !important;
  }

  &.mat-focused {
    .mdc-text-field:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid) .mdc-floating-label {
      color: var(--primaryColor) !important;
    }

    .mat-mdc-select-arrow {
      color: var(--primaryColor) !important;
    }
  }

  &.read-only {
    pointer-events: none;

    input:read-only {
      cursor: auto;
    }

    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
      background-color: trarensparent !important;
    }

    .mdc-text-field:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid) .mdc-floating-label {
      color: #7c7c7c !important;
      pointer-events: none;
    }

    .mat-mdc-form-field-focus-overlay {
      opacity: 0 !important;
    }

    .mdc-line-ripple::after {
      transform: scaleX(1);
      opacity: 1;
      z-index: 2;
      border-bottom-width: 1.5px;
      border-bottom-color: black !important
    }
  }

  .mat-mdc-form-field-bottom-align::before {
    display: flex !important;
    height: unset !important
  }

  .mat-mdc-form-field-hint-wrapper,
  .mat-mdc-form-field-error-wrapper {
    position: unset !important;
    margin-top: 5px !important;
  }
}

// Scrollbar
.app-scrollbar,
.mat-mdc-select-panel,
.mat-mdc-autocomplete-panel,
.ng2-pdf-viewer-container,
.mat-autocomplete-panel {
  // scrollbar-width: thin; // Firefox
  // scrollbar-color: red transparent;

  &::-webkit-scrollbar {
    // Chrome, Edge, Safari and Opera
    height: 5px;
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--primaryColor);
    border-radius: 8px;

    &:hover {
      background: var(--primaryColor);
    }

    &:focus {
      background: var(--primaryColor);
    }
  }
}

// App Input
.app-input {
  width: 100%;
  font-size: 14px !important;

  &:not(.textarea) {
    .mat-mdc-text-field-wrapper {
      height: 48px !important;
    }

    .mat-mdc-form-field-infix {
      min-height: 48px !important;
      height: 48px !important;
      padding-top: 12px !important;
      padding-bottom: 12px !important;
    }

    .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
      top: 50%;
      font-size: 14px !important;
    }

    .mat-mdc-form-field-input-control {
      height: 20px;
    }
  }

  &.search {
    .mat-mdc-form-field-subscript-wrapper {
      display: none !important;
    }

    .mdc-notched-outline__leading {
      border-top: solid 1px #f5f5f5 !important;
      border-bottom: solid 1px #f5f5f5 !important;
      border-right: none !important;
      border-left: solid 1px #f5f5f5 !important;
    }

    .mdc-notched-outline__trailing {
      border-top: solid 1px #f5f5f5 !important;
      border-bottom: solid 1px #f5f5f5 !important;
      border-right: solid 1px #f5f5f5 !important;
      border-left: none !important;
    }

    .mat-mdc-text-field-wrapper {
      height: 38px !important;
    }

    .mat-mdc-form-field-infix {
      min-height: 38px !important;
      height: 38px !important;
      padding-top: 8px !important;
      padding-bottom: 8px !important;
    }

    .mat-mdc-form-field-icon-suffix {
      padding: 0;
    }

    .mat-mdc-icon-button.mat-mdc-button-base {
      --mdc-icon-button-state-layer-size: 38px !important;
      padding: 0 !important;
      color: gray;

      &.active {
        color: black;
      }
    }

    .mat-mdc-icon-button .mat-mdc-button-touch-target {
      width: 38px;
      height: 38px;
    }
  }

  &.read-only.textarea {
    pointer-events: none;
  }

  .app-input-suffix {
    font-size: 18px;
    // min-width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.time-picker {
    // .mat-mdc-input-element::-webkit-calendar-picker-indicator {
    //     display: block;
    // }
  }

  &.small {
    .mat-mdc-text-field-wrapper {
      height: 28px !important;
      padding: 0 8px 0 8px;
    }

    .mat-mdc-select-trigger {
      font-size: 12px !important;
    }

    .mat-mdc-form-field-infix {
      min-height: 28px !important;
      height: 28px !important;
      padding-top: 2px !important;
      padding-bottom: 2px !important;
    }

    .mat-mdc-icon-button .mat-mdc-button-touch-target {
      width: 28px;
      height: 28px;
    }

    .mat-mdc-icon-button.mat-mdc-button-base {
      --mdc-icon-button-state-layer-size: 28px !important;
      padding: 0 !important;
    }

    .mat-mdc-icon-button {
      --mat-mdc-button-persistent-ripple-color: transparent !important;
      --mat-mdc-button-ripple-color: transparent !important;
    }

    svg {
      width: 20px;
      height: 20px;
    }

  }
}

// App Input Searchbar
.app-input-searchbar {
  width: 100%;
  font-size: 14px !important;
  padding: 0px !important;
  border: none;

  &:not(.textarea) {
    .mat-mdc-text-field-wrapper {
      height: 34px !important;
    }

    // .mat-mdc-form-field-infix {
    //   min-height: 34px !important;
    //   height: 34px !important;
    //   padding-top: 2px !important;
    //   padding-bottom: 2px !important;
    // }

    .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
      top: 50%;
      font-size: 14px !important;
    }

    .mat-mdc-form-field-input-control {
      height: 28px !important;
      padding: 0 !important;
    }
  }

  &.search {
    .mat-mdc-form-field-subscript-wrapper {
      display: none !important;
    }

    .mdc-notched-outline__leading {
      border: none !important;
    }

    .mdc-notched-outline__trailing {
      border: none !important;
    }

    .mat-mdc-text-field-wrapper {
      height: 34px !important;
    }

    .mat-mdc-form-field-infix {
      min-height: 34px !important;
      height: 34px !important;
      padding-top: 2px !important;
      padding-bottom: 2px !important;
    }

    .mat-mdc-form-field-icon-suffix {
      padding: 0;
    }

    .mat-mdc-icon-button.mat-mdc-button-base {
      --mdc-icon-button-state-layer-size: 28px !important;
      padding: 0 !important;
      color: black;

      &.active {
        color: black;
      }
    }

    .mat-mdc-icon-button .mat-mdc-button-touch-target {
      width: 34px;
      height: 34px;
    }
  }

  &.read-only.textarea {
    pointer-events: none;
  }

  .app-input-suffix {
    font-size: 18px;
    min-width: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}


.mat-mdc-form-field {
    padding-top: 20px;

    &.small {
        padding: 0;
    }
}

.mat-mdc-form-field.search {
  padding-top: 0px;
}

.only-input {
  border-width: 0 0px 1px 0;
  outline: none !important;
  width: 100%;

  &:focus {
    border-color: var(--primaryColor);
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

// App Select
.app-select {
  width: 100%;

  .mat-mdc-text-field-wrapper {
    height: 48px !important;
  }

  .mat-mdc-select-trigger {
    font-size: 14px !important;
  }

  .mat-mdc-form-field-infix {
    min-height: 48px !important;
    height: 48px !important;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }

  .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
    top: 50%;
    font-size: 14px !important;
  }

  .mat-mdc-form-field-input-control {
    height: 20px;
  }

  &.read-only {
    --mat-select-disabled-trigger-text-color: black !important;
    --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0) !important;


    .mdc-text-field--disabled .mdc-notched-outline__leading,
    .mdc-text-field--disabled .mdc-notched-outline__notch,
    .mdc-text-field--disabled .mdc-notched-outline__trailing {
      border-color: rgba(0, 0, 0, 0.38) !important;
    }

    .mdc-text-field--disabled .mdc-floating-label {
      color: #7c7c7c !important;
    }
  }

    .count-number {
        padding: 3px 5px;
        background-color: var(--primaryColor);
        color: var(--textPrimaryColor);
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        border-radius: 4px;
        margin-right: 5px;
        display: inline-flex;
        text-align: center;
        justify-content: center;
    }

    &.small {
        .mat-mdc-text-field-wrapper {
            height: 28px !important;
            padding: 0 8px 0 8px;
        }

        .mat-mdc-select-trigger {
            font-size: 12px !important;
        }

        .mat-mdc-form-field-infix {
            min-height: 28px !important;
            height: 28px !important;
            padding-top: 2px !important;
            padding-bottom: 2px !important;
        }
    }
}

app-select.white-background {
  .mat-mdc-text-field-wrapper {
    background: white;
  }
}

.app-select-searchbar.mat-mdc-form-field {
  padding: 0 !important;
  display: flex;
  align-items: center;
}

.app-select-searchbar {
  width: 100%;
  font-size: 14px !important;
  padding: 0 !important;
  display: flex;
  align-items: center;

  .mat-mdc-form-field-subscript-wrapper {
    display: none !important;
  }

  .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
    top: 50%;
    font-size: 14px !important;
    margin-left: 8px;
  }

  .mdc-notched-outline__leading,
  .mdc-notched-outline__notch,
  .mdc-notched-outline__trailing {
    border: none !important;
  }

  .mat-mdc-select-trigger {
    font-size: 14px !important;
  }

  .mat-mdc-form-field-input-control {
    height: 28px !important;
    padding: 0 !important;
  }

  &.read-only {
    --mat-select-disabled-trigger-text-color: black !important;
    --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0) !important;

    .mdc-text-field--disabled .mdc-notched-outline__leading,
    .mdc-text-field--disabled .mdc-notched-outline__notch,
    .mdc-text-field--disabled .mdc-notched-outline__trailing {
      border-color: rgba(0, 0, 0, 0.38) !important;
    }
  }

  .count-number {
    padding: 3px 5px;
    background-color: var(--primaryColor);
    color: var(--textPrimaryColor);
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    border-radius: 4px;
    margin-right: 5px;
    display: inline-flex;
    text-align: center;
    justify-content: center;
  }
}


mat-option {
  font-size: 14px !important;

  &:hover {
    background-color: var(--hoverPrimaryColor) !important;

    span {
      color: var(--hoverTextPrimaryColor);
    }

    mat-icon {
      color: var(--hoverTextPrimaryColor);
    }
  }

  --mat-option-selected-state-layer-color: var(--primaryColor) !important;
  --mat-option-selected-state-label-text-color: var(--textPrimaryColor) !important;
  --mat-option-focus-state-layer-color: transparent !important;

  &.mat-mdc-option-multiple {
    --mat-option-selected-state-label-text-color: black !important;

    mat-pseudo-checkbox {
      width: 16px;
      height: 16px;
    }
  }

    &.small {
        font-size: 12px !important;

        mat-pseudo-checkbox {
            width: 12px;
            height: 12px;
        }

        .mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-checked::after {
            width: 10px !important;
            height: 4px !important;
        }
    }

    &.small {
        font-size: 12px !important;

        mat-pseudo-checkbox {
            width: 12px;
            height: 12px;
        }

        .mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-checked::after {
            width: 10px !important;
            height: 4px !important;
        }
    }

}

.mat-pseudo-checkbox-checked {
  &::after {
    color: var(--textPrimaryColor) !important;
  }
}

// App Table

.mat-mdc-table {
  table-layout: fixed !important;
  width: 5px !important;
}

.mat-elevation-z8 {
  border-radius: 8px !important;
  box-shadow: unset;
}

.app-table {

  .mat-mdc-cell {
    &.success {
      color: #1fa856 !important;
    }

    &.error {
      color: #e32222 !important;
    }

    &.pending {
      color: #ff9061 !important;
    }

    &.inactive {
      color: #a1a1a1 !important;
    }

    &.approved {
      color: #354d98 !important;
    }
  }

  .mat-column-actionsdetail {
    width: 100px;
    text-align: center;
  }

  .mat-column-select {
    width: 32px;
    text-align: center;
    padding: 0;

    .mdc-checkbox {
      width: 16px !important;
      height: 16px !important;
      flex: unset;
    }

    .mdc-checkbox__background {
      width: 16px;
      height: 16px;
    }

    .mdc-checkbox__ripple,
    .mat-mdc-checkbox-ripple {
      display: none !important;
    }
  }

  .mat-column-stato {
    width: 32px;
    text-align: center;
    padding: 0;

    mat-icon {
      font-size: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
  }

  .mat-column-log_date, .mat-column-log_module {
    width: 180px;
  }


  .mat-mdc-cell.mat-column-odl-article-status {
    font-weight: bold;
  }

  .mat-mdc-cell.mat-column-status_info {
    font-weight: bold;

    mat-icon {
      font-size: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: visible;
    }
  }

    .mat-column-actions {
        width: 32px;
        text-align: center;
        padding: 0;
    }

    .mat-column-button {
        text-align: end;
        padding-left: 0;
    }

  .mat-column-detail {
    text-align: center;
    width: 48px;
    padding: 0;
  }

  .mat-column-goto {
    text-align: center;
    width: 48px;
    padding: 0;
  }

  .mat-column-download {
    text-align: center;
    width: 48px;
    padding: 0;
  }

  .mat-column-email {
    a {
      cursor: pointer;
      color: black;
    }
  }

  .mat-column-denominazione {

    &.no-padding {
      padding-left: 0;
    }
  }

    .mat-column-stato_validita {
        .status-div {
            font-weight: bold;
        }
    }

    .mat-column-esito_revisione {
        width: 160px;
    }

  .mat-mdc-no-data-row {
    text-align: center;
    height: 100px;
  }

  .mat-mdc-header-row {
    background-color: #f6f8fa;
    height: 36px;
  }

  .mat-mdc-header-cell {
    font-size: 12px;
    border: none !important;
    color: #38425a;
  }

  .mdc-data-table__cell {
    font-size: 13px;
    border: none !important;

    &.clickable {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .mat-mdc-row:nth-child(2n+1) {
    background-color: #ffffff;
  }

  .mat-mdc-row:not(:nth-child(2n+1)) {
    background-color: #f7f7f7;
  }

    .mat-mdc-row:hover:not(.row-disabled) {
        background-color: var(--hoverPrimaryColor) !important;
    }

    .mat-mdc-row.row-disabled {
        opacity: 0.5;
    }

  .sub-row-label {
    font-size: 10px;
    color: #a0a0a0;
    line-height: normal;
  }
}

.table-container {
  height: calc(100% - 56px);

  .mat-expansion-panel-header {
    margin: 0 !important;
    padding: 0px 10px 0 0 !important;
  }

  .mat-expansion-panel-header.mat-expanded {
    height: unset !important;
  }

  .mat-expansion-panel-content {
    padding: 0 !important;
  }
}

.table-filter-container {
  app-page-title {
    width: fit-content;
  }

  .searchbar {
    width: auto;

    .search-button-container.opened {
      width: 200px;
      max-width: 200px;
      min-width: 200px;
    }

    .search-button {
      border: none !important;
      outline: none !important;
      background-color: transparent !important;
      padding: 0 !important;

      &:disabled {
        .search-icon {
          opacity: 0.4;
        }
      }

      .search-icon {
        cursor: pointer;
      }
    }

    .mat-mdc-form-field-infix {
      width: unset !important;
    }
  }

  .actions-col {
    max-width: fit-content;
  }

}

.table-column {
  height: 100%;
  overflow: auto;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}

.filter-column {
  width: 180px;
  max-width: 180px;
  min-width: 180px;
  height: 100%;
  overflow: auto;
  margin-right: 20px !important;

  .filter-row {
    padding-bottom: 10px !important;
    padding-top: 10px !important;
    border-bottom: 1px solid #f0f0f0;
    border-top: 1px solid #f0f0f0;
  }

  .filter-row:first-child {
    padding-top: 0 !important;
    border-top: none !important;
  }

  .filter-row:last-child {
    padding-bottom: 0 !important;
    border-bottom: none !important;
  }

  .filter-label {
    font-size: 10px;
    font-weight: 500;
  }

  .filter-checkbox {
    --mdc-checkbox-state-layer-size: 18px !important;

    .mat-mdc-checkbox {
      margin-top: 10px;
    }

    .mdc-checkbox {
      width: 16px !important;
      height: 16px !important;
      flex: unset;
    }

    .mat-mdc-checkbox-touch-target {
      height: 16px !important;
      width: 16px !important;
    }

    .mdc-checkbox__background {
      width: 16px !important;
      height: 16px !important;
    }

    .mat-mdc-checkbox .mdc-form-field {
      font-size: 10px;
    }

    .mat-mdc-checkbox label {
      padding-left: 8px !important;
    }
  }
}

mat-paginator {
  position: sticky;
  bottom: 0;
  border-radius: 0 0 8px 8px;
  --mat-paginator-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-paginator-container-background-color: white;
  --mat-paginator-enabled-icon-color: black;
  --mat-paginator-disabled-icon-color: rgba(0, 0, 0, 0.12);
  --mat-paginator-container-size: 32px !important;

  &.no-sticky {
    position: relative !important;
  }

  .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
    opacity: 1 !important;
    z-index: -1;
  }

  .mat-mdc-icon-button:hover {
    .mat-mdc-paginator-icon {
      fill: var(--primaryColor);
    }
  }

  .mat-mdc-icon-button .mat-mdc-button-persistent-ripple::before {
    background-color: var(--hoverPrimaryColor) !important;
  }

  .mat-mdc-icon-button .mat-mdc-button-persistent-ripple {
    border-radius: 8px;
  }

  .mat-mdc-icon-button {
    padding: 0 !important;
    width: 32px !important;
    height: 32px !important;
  }

  .mat-mdc-icon-button .mat-mdc-button-touch-target {
    height: 32px !important;
    width: 32px !important;
  }

  .mat-mdc-paginator-container {
    padding: 0 !important;
    margin-top: 20px;
  }

  .mat-mdc-paginator-page-size {
    align-items: center !important;
  }

  .mat-mdc-form-field {
    padding-top: 0 !important;

    .mat-mdc-text-field-wrapper {
      height: 32px;
    }

    .mat-mdc-form-field-flex {
      height: 32px;
    }

    .mat-mdc-form-field-infix {
      padding-top: 3px !important;
      padding-bottom: 3px !important;
      height: 32px !important;
      min-height: 32px !important;
      max-height: 32px !important;
    }
  }
}

// A link
.app-link {
  color: var(--primaryColor);
  cursor: pointer;
  text-decoration: none;
}

// Checkbox
.mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background,
.mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background,
.mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background {
  border-color: var(--primaryColor) !important;
  background-color: var(--primaryColor) !important;
}

.mdc-checkbox .mdc-checkbox__native-control:focus:checked~.mdc-checkbox__ripple {
  background-color: var(--primaryColor) !important;
}

.mdc-checkbox .mdc-checkbox__native-control:focus~.mdc-checkbox__ripple {
  opacity: var(--mdc-checkbox-unselected-focus-state-layer-opacity);
  background-color: transparent !important;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full {
  background: var(--primaryColor) !important;

  &:hover {
    background: var(--hoverPrimaryColor) !important;
  }
}

// Buttons
.app-button {
  --mat-mdc-button-persistent-ripple-color: transparent !important;
  --mat-mdc-button-ripple-color: transparent !important;
  background-color: var(--primaryColor) !important;
  color: var(--textPrimaryColor) !important;
  border-radius: 8px !important;
  height: 48px !important;
  padding: 15px 38px !important;
  text-transform: uppercase;
  box-shadow: none !important;

  &.no-background {
    background-color: transparent !important;
  }

  &:hover,
  &.hover {
    background-color: var(--hoverPrimaryColor) !important;
    color: var(--hoverTextPrimaryColor) !important;
  }

  &.stroked {
    border: 1px solid var(--primaryColor) !important;
    background-color: var(--textPrimaryColor) !important;
    color: var(--primaryColor) !important;

    &.no-border {
      border: none !important;
    }

    &:hover {
      background-color: var(--hoverPrimaryColor) !important;
      color: var(--hoverTextPrimaryColor) !important;
    }
  }

  &.small-button {
    height: 32px !important;
    padding: 6px 8px !important;
  }

  &.basic {
    border: none !important;
    background-color: transparent !important;
    color: black !important;
  }

  &.no-border {
    border: none !important;
  }

  &:disabled {
    opacity: 0.5 !important;
    pointer-events: none;
  }

  &.fill {
    width: 100%;
  }
}

.mat-mdc-button {
  --mat-mdc-button-persistent-ripple-color: transparent !important;
}

.extrasmall-icon {
  width: 22px !important;
  height: 22px !important;
  padding: 0px !important;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;

  &>*[role=img] {
    width: 20px;
    height: 20px;
    font-size: 20px;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .mat-mdc-button-touch-target {
    width: 22px !important;
    height: 22px !important;
  }
}

.small-icon-button {
  width: 32px !important;
  height: 32px !important;
  padding: 0px !important;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;

  &>*[role=img] {
    width: 24px;
    height: 24px;
    font-size: 24px;

    svg {
      width: 24px;
      height: 24px;
    }
  }

  .mat-mdc-button-touch-target {
    width: 32px !important;
    height: 32px !important;
  }
}

.medium-icon-button {
  width: 38px !important;
  height: 38px !important;
  padding: 0px !important;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;

  &>*[role=img] {
    width: 28px;
    height: 28px;
    font-size: 26px;

    svg {
      width: 28px;
      height: 28px;
    }
  }

  .mat-mdc-button-touch-target {
    width: 38px !important;
    height: 38px !important;
  }
}

// Container sidenav menu
.main-container {
  height: 100%;

  .sidenav-menu {
    display: flex;
    height: 100%;
    width: 220px;
    border: none;
    background-color: var(--sidebarColor);
  }

  .sidenav-content {
    height: 100%;

    .mini-sidenav-menu {
      max-width: 70px;
      width: 70px;
      border: none;
      background-color: var(--sidebarColor);
    }

    .pages-container {
      padding: 32px 32px;
      // height: calc(100% - 100px); //Footer
      height: calc(100% - 60px);
      overflow: auto;
      background-color: #f0f0f0;

      .page-header {
        //padding-bottom: 32px;
        padding-bottom: 5px;
        line-height: normal;

        .sub-row-label-detail {
          font-size: 14px;
          color: black;

          &.no-padding {
            padding-left: 0 !important;
          }
        }
      }

      .page-card {
        background-color: white;
        padding: 32px 20px;
        border-radius: 10px;

        &.full {
          height: 100%;
        }

        .footer {
            position: sticky;
            background: white;
            bottom: -32px;
            left: 0;
            margin-top: 32px;
            padding: 16px 0;
            border-top: 1px solid #f0f0f0;
            z-index: 2;
        }
      }

      .page-tab-selector {
        padding-left: 20px;

        .page-tab {
          background-color: #eae9e9;
          font-size: 10px;
          height: 28px;
          padding: 4px 18px;
          border-radius: 10px 10px 0 0;
          cursor: pointer;
          min-width: fit-content;

          &.not-selectable {
            cursor: auto;
          }

          &:hover:not(.selected):not(.not-selectable) {
            background-color: var(--hoverPrimaryColor);
            color: var(--hoverTextPrimaryColor);
          }

          &.selected {
            background-color: white;
            font-weight: bold;
            color: var(--primaryColor);
          }
        }
      }

      .mat-expansion-panel {
        box-shadow: unset !important;
        box-sizing: border-box;
        padding: 0;
      }

      .mat-expansion-panel-header {
        height: unset;
        // font-weight: bold;
        font-size: 14px;
        padding: 18px 0;
        margin-left: 12px;
        margin-right: 12px;

        &:hover {
          background: none !important;
        }

        &.mat-expanded {
          height: 48px;
        }
      }

      .mat-expansion-panel-body {
        padding: 0;
      }

      .section-title {
        font-size: 20px;
        font-family: 'Inter-Bold', sans-serif;
        letter-spacing: normal;

        &.big {
          font-size: 26px;
        }

        &.border-top {
            border-top: 1px solid #f0f0f0;
        }

        &.small {
            font-size: 16px;
        }
      }

      .section-subtitle {
          color: #7c7c7c;
          font-size: 14px;
      }

      .section-add {

        span {
          font-family: 'Inter-Bold', sans-serif !important;
          font-size: 14px;
          color: var(--primaryColor);
          cursor: pointer;
        }

      }

      .sub-entity-container {
        border-bottom: 1px solid #f0f0f0;
        border-top: 1px solid #f0f0f0;
        // border: 1px solid var(--primaryColor);
        // border-radius: 8px;
        // margin: 0.75rem !important;
        padding: 18px 0 !important;

        &.no-border-bottom {
          border-bottom: none;
        }

        &.no-border-top {
          border-top: none;
        }

        &.no-padding {
          padding: 0 !important;
        }

        &.no-padding-top {
          padding-top: 0 !important;
        }

        .sub-entity-name {
          // font-weight: bold;
        }
      }
    }
  }

  .menu-item-container {
    padding: 5px 10px;

    &.children {
      padding: 4px 10px 4px 10px;
    }

    &.mini {
      width: 100%;
    }

    &.footer {
      margin-top: auto;
    }

    &.expand {
      width: 100%;
    }
  }

  .menu-item {
    display: flex;
    align-items: center;
    height: 44px;
    width: 100%;
    padding: 10px 0;
    border-radius: 10px;
    cursor: pointer;

    &.children {
      height: 36px;
      font-size: 14px;
      background-color: transparent !important;

      &:hover {
        opacity: 0.6;
      }
    }

    &.parent {
      display: contents;

      mat-expansion-panel {
        background: transparent;
        box-shadow: none;
        border-radius: 0;

        &.mini {
          width: 100%;

          .mat-content.mat-content-hide-toggle {
            margin-right: 0 !important;
            padding-right: 0 !important;
          }
        }
      }

      mat-expansion-panel-header {
        height: 44px;
        padding: 10px 20px;
        margin: 5px 10px;
        border-radius: 10px;

        &.selected {
          background: var(--sidebarItemSelectedColor) !important;

          .menu-text {
            color: var(--primaryColor) !important;
            font-weight: bold;
          }

          mat-icon {
            color: var(--sidebarItemTextSelectedColor) !important;
          }

          .mat-expansion-indicator::after {
            color: var(--sidebarItemTextSelectedColor) !important;
          }
        }

        &.mini {
          padding: 0 !important;
          display: flex;
          align-items: center;
          justify-content: center;

          .mat-content {
            flex: unset !important;
            padding-right: 5px;
          }
        }

        .mat-expansion-indicator::after {
          color: var(--sidebarTextColor) !important;
        }

        .menu-text {
          padding: 0;
        }

        &:hover:not(.selected) {
          background: var(--sidebarItemHoverColor) !important;
          color: var(--sidebarItemTextHoverColor);

          span {
            color: var(--sidebarItemTextHoverColor);
          }

          mat-icon {
            color: var(--sidebarItemTextHoverColor);
          }
        }
      }

      .mat-expansion-panel-body {
        padding: 0 !important;

        // .menu-text {
        //     padding: 0 20px 0 25px;
        // }
      }

      &.menu-text {
        padding: 0 20px;
      }
    }



    &.mini {
      justify-content: center;
    }

    &.first {
      margin-top: 32px;
    }

    mat-icon {
      width: auto;
      height: auto;
      font-size: 28px;
      color: var(--sidebarTextColor);
    }

    .menu-text {
      padding: 0 0 0 20px;
      width: 100%;
      display: flex;
      align-items: center;
      color: var(--sidebarTextColor);
    }

    span.label {
      padding-left: 10px
    }

    &:hover:not(.parent):not(.selected) {
      background-color: var(--sidebarItemHoverColor);
      color: var(--sidebarItemTextHoverColor);

      span {
        color: var(--sidebarItemTextHoverColor);
      }

      mat-icon {
        color: var(--sidebarItemTextHoverColor);
      }
    }

    &.selected {
      background-color: var(--sidebarItemSelectedColor);
      color: var(--sidebarItemTextSelectedColor);

      span {
        color: var(--primaryColor) !important;
        font-weight: bold;
      }

      mat-icon {
        color: var(--sidebarItemTextSelectedColor);
      }
    }
  }

  .header-menu-item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 60px;
    padding: 0 10px;

    .menu-header-text {
      height: 100%;
      width: 100%;
      height: 50px;
    }

    .logo {
      height: 100%;
      width: 100%;
      object-fit: contain
    }
  }
}

// Mat Tab
mat-tab-header {
  --mdc-tab-indicator-active-indicator-color: var(--primaryColor) !important;
  --mat-tab-header-disabled-ripple-color: transparent !important;
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: black;
  --mat-tab-header-active-label-text-color: var(--primaryColor) !important;
  --mat-tab-header-active-ripple-color: transparent !important;
  --mat-tab-header-inactive-ripple-color: transparent !important;
  --mat-tab-header-inactive-focus-label-text-color: black;
  --mat-tab-header-inactive-hover-label-text-color: black;
  --mat-tab-header-active-focus-label-text-color: var(--primaryColor) !important;
  --mat-tab-header-active-hover-label-text-color: var(--primaryColor) !important;
  --mat-tab-header-active-focus-indicator-color: transparent !important;
  --mat-tab-header-active-hover-indicator-color: var(--primaryColor) !important;
  margin: 0;

  .mdc-tab__ripple::before {
    border-radius: 8px 8px 0 0;
  }

  .mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
    font-weight: bold;
  }

  .mdc-tab-indicator__content {
    opacity: 0.1 !important;
  }

  .mdc-tab-indicator--active .mdc-tab-indicator__content {
    opacity: 1 !important;
  }

  .mat-mdc-tab {
    background-color: #eae9e9;
    border-radius: 10px 10px 0 0;
    padding: 4px 18px;
    align-items: center;

    &.mdc-tab--active {
      background-color: white;
      font-weight: bold;
    }

    &:hover:not(.mdc-tab--active) {
      background-color: var(--hoverPrimaryColor);
      color: var(--hoverTextPrimaryColor);
    }
  }
}

.mat-mdc-tab-header {
  --mdc-tab-indicator-active-indicator-height: 0px !important;
  --mdc-tab-indicator-active-indicator-shape: 0;
  --mdc-secondary-navigation-tab-container-height: 28px !important;
  --mat-tab-header-label-text-font: Inter;
  --mat-tab-header-label-text-size: 10px;
  --mat-tab-header-label-text-line-height: 36px;
  --mat-tab-header-label-text-weight: normal;
}

mat-tab-body {
  z-index: 0 !important;
}

.portfolio-tab {
  mat-tab-header {
    --mat-tab-header-active-label-text-color: var(--textPrimaryColor) !important;
    --mat-tab-header-active-focus-label-text-color: var(--textPrimaryColor) !important;
    --mat-tab-header-active-hover-label-text-color: var(--textPrimaryColor) !important;
    --mdc-tab-indicator-active-indicator-height: 0px !important;
  }

  .mat-mdc-tab-header {
    margin: 0;
  }

  .mat-mdc-tab {
    flex-grow: 0 !important;
    border-radius: 10px 10px 0 0;
    background-color: white;
    height: 28px !important;
    font-size: 10px;

    &.mdc-tab--active {
      background-color: var(--primaryColor);
    }
  }

  .mat-mdc-tab-labels {
    justify-content: flex-end;
  }
}

// App-tooltip

.app-tooltip {
  background-color: var(--primaryColor);
  padding: 12px 16px;
  font-size: 14px;
  border-radius: 10px;
  color: white;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);

  &.white {
    background-color: white;
    color: black;

    span {
      font-family: 'Inter-Bold', sans-serif;

      &.success {
        color: #1fa856;
      }

      &.error {
        color: #e32222;
      }

      &.pending {
        color: #ff9061;
      }

      &.inactive {
        color: #a1a1a1;
      }
    }
  }

  &.popup {
    border-radius: 10px;
    background-color: white !important;
    color: #000 !important;
  }
}

.status-tooltip {
  border-radius: 10px !important;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);

  .mdc-tooltip__surface {
    max-width: 500px !important;
    padding: 0 !important;
    border-radius: 10px;
  }
}

.mat-mdc-tooltip {
  .mdc-tooltip__surface {
    max-width: 500px !important;
    background-color: var(--primaryColor) !important;
    padding: 6px 16px;
    font-size: 14px;
  }
}

// App Badge

.mat-badge-content {
  border: solid 1px var(--primaryColor);
  background: #fff !important;
  color: black !important;
  /* width: 18px !important; */
  /* height: 18px !important; */
  font-size: 10px;
  line-height: normal !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

// App dialog
.mdc-dialog__actions {
  padding: 8px 32px 32px 32px !important;
}

.mdc-dialog__content {
  padding: 32px !important;
  color: black !important;
  font-size: 14px;

  .justify {
    text-align: justify;
  }

  &.grid {
    padding: 32px 20px !important;
  }
}

.mdc-dialog__title {
  margin: 0 !important;
  padding: 32px 32px 0 !important;
  font-family: 'Inter-Bold', sans-serif !important;
  line-height: unset !important;

  &.subtitle {
    color: #7c7c7c !important;
    font-size: 12px !important;
    font-family: 'Inter' !important;
    padding-top: 0 !important;
  }
}

.mdc-dialog__title::before {
  height: unset !important;
}

.mat-divider {
  margin: 20px 0 !important;
}

// Ngx file drop
.ngx-file-drop__drop-zone {
  border: dotted 2px #7c7c7c !important;

  .ngx-file-drop__content {
    color: black !important;
    font-size: 12px;

    mat-icon {
      width: 56px;
      height: 56px;
      font-size: 56px;
      color: #7c7c7c;
    }

    .text {
      width: fit-content;
    }

    .link {
      width: fit-content;
      font-family: 'Inter-Bold', sans-serif !important;
      text-decoration: underline;
      color: var(--primaryColor);
      cursor: pointer;
    }
  }
}

.anagrafica-logo {
  width: fit-content;

  .ngx-file-drop__drop-zone {
    border: solid 1px var(--primaryColor) !important;
    border-radius: 50%;
    width: 50px !important;
    height: 50px !important;

    .ngx-file-drop__content {
      color: black !important;
      font-size: 12px;
      width: 50px !important;
      height: 50px !important;
      cursor: pointer;

      mat-icon {
        width: 24px;
        height: 24px;
        font-size: 24px;
        color: var(--primaryColor);
      }
    }
  }
}

.app-segment {
  // background-color: #f5f5f5;
  padding: 5px 0;
  display: flex;
  border-radius: 10px;
  font-family: 'Inter', sans-serif;
  height: 38px;
  margin-left: 10px;

  .segment-button {
    // border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    //line-height: 1.2;
    letter-spacing: normal;
    min-width: 70px;
    border: 1px solid var(--primaryColor);
    color: var(--primaryColor);
    cursor: pointer;

    &.active {
      background-color: var(--primaryColor);
      color: var(--textPrimaryColor);
    }

    &:first-child {
      border-radius: 4px 0 0 4px;
      border-right: 0;
    }

    &:last-child {
      border-left: 0;
      border-radius: 0 4px 4px 0;
    }
  }
}

.app-breadcrump {
  display: flex;
  margin-bottom: 20px;

  .item {
    color: #7c7c7c;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    cursor: pointer;

    &.active {
      color: black;
      cursor: default;
    }

    &:hover:not(.active) {
      color: var(--primaryColor);
    }
  }

  .item-separator {
    font-size: 20px;
    margin: 0 5px;
  }
}

// APP SLIDER
.app-slider {
  --mdc-slider-handle-color: var(--primaryColor) !important;
  --mdc-slider-focus-handle-color: var(--primaryColor) !important;
  --mdc-slider-hover-handle-color: var(--primaryColor) !important;
  --mdc-slider-active-track-color: var(--primaryColor) !important;
  --mdc-slider-inactive-track-color: var(--primaryColor) !important;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: var(--primaryColor) !important;
  --mat-mdc-slider-hover-ripple-color: trasparent !important;
  --mat-mdc-slider-focus-ripple-color: trasparent !important;
  --mat-mdc-slider-ripple-color: var(--primaryColor) !important;
}

.mat-mdc-slider .mdc-slider__track {
  height: 8px !important;
}

.mat-mdc-slider .mdc-slider__track--inactive {
  height: 8px !important;
  background-color: #f0f0f0 !important;
  opacity: 1 !important;
}

.mat-mdc-slider .mdc-slider__track--active {
  height: 8px !important;
}

.mat-mdc-slider .mdc-slider__track--active_fill {
  border-top-width: 8px !important;
}

// APP Progress bar
.app-progress-bar {
  border-radius: 10px;
  --mdc-linear-progress-active-indicator-color: var(--primaryColor);
  --mdc-linear-progress-track-color: #d9d9d9;
  --mdc-linear-progress-active-indicator-height: 8px !important;
  --mdc-linear-progress-track-height: 8px !important;
  // --mdc-linear-progress-track-shape: 10px !important;
}

// App switch
.app-switch {
  --mdc-switch-selected-focus-state-layer-color: var(--primaryColor) !important;
  --mdc-switch-selected-handle-color: white !important;
  --mdc-switch-selected-hover-state-layer-color: white !important;
  --mdc-switch-selected-pressed-state-layer-color: var(--primaryColor) !important;
  --mdc-switch-selected-focus-handle-color: white !important;
  --mdc-switch-selected-hover-handle-color: white !important;
  --mdc-switch-selected-pressed-handle-color: white !important;
  --mdc-switch-selected-focus-track-color: var(--primaryColor) !important;
  --mdc-switch-selected-hover-track-color: var(--primaryColor) !important;
  --mdc-switch-selected-pressed-track-color: var(--primaryColor) !important;
  --mdc-switch-selected-track-color: var(--primaryColor) !important;

  --mdc-switch-unselected-focus-handle-color: white !important;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #7c7c7c;
  --mdc-switch-unselected-handle-color: white !important;
  --mdc-switch-unselected-hover-handle-color: white !important;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: #7c7c7c;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: white !important;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: #7c7c7c;
  --mdc-switch-unselected-track-color: #7c7c7c;


  --mdc-switch-disabled-selected-handle-color: white !important;
  --mdc-switch-disabled-selected-icon-color: white !important;
  --mdc-switch-disabled-selected-track-color: var(--primaryColor) !important;
  --mdc-switch-disabled-unselected-handle-color: white !important;
  --mdc-switch-disabled-unselected-icon-color: white !important;
  --mdc-switch-disabled-unselected-track-color: #7c7c7c !important;
}

.mdc-switch {
  --mdc-switch-selected-icon-size: 18px !important;
  --mdc-switch-track-height: 24px !important;
  --mdc-switch-track-shape: 12px !important;
  --mdc-switch-track-width: 48px !important;

  --mdc-switch-disabled-handle-opacity: 0.6 !important;
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.6 !important;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
}

.mdc-switch--selected .mdc-switch__handle-track {
  transform: translateX(95%) !important;
}

.mdc-switch__handle-track {
  transform: translateX(5%) !important;
}

// App mapp
.leaflet-container {
    z-index: 199;
}
.leaflet-popup {
  width: 344px;

  .app-button {
    border-radius: 0px 0px 4px 4px !important;
    width: 100%;
    border: none !important;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.86;
    letter-spacing: normal;
    text-transform: none;
  }
}

.leaflet-popup-content-wrapper {
  border-radius: 4px !important;
  padding: 0 !important;
}

.leaflet-popup-content {
  font-family: 'Inter' !important;
  margin: 0 !important;
  width: 100% !important;
  color: black;
}

.leaflet-popup-close-button {
  display: none !important;
}

.popup-image-container {
  height: 200px;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}

.popup-body {
  max-height: 268px;
  overflow: auto;
}

.popup-container {
  padding: 16px;

  .popup-info {
    margin-bottom: 20px;

    &.first {
      margin-top: 20px;
    }
  }

  .popup-icon {
    color: #a0a0a0;
  }

  .popup-info-text {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-transform: capitalize;
  }

  .popup-title {
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.11;
    letter-spacing: normal;
    color: var(--primaryColor);
    margin-bottom: 6px;

    mat-icon {
      height: 12px;
      width: 12px;
      font-size: 12px;

      &.success {
        color: #1fa856;
      }

      &.error {
        color: #e32222;
      }

      &.pending {
        color: #ff9061;
      }

      &.inactive {
        color: #a1a1a1;
      }
    }
  }
}

.legend-container {
  padding: 9px;
  background: white;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  border-radius: 4px;

  .legend-item {
    width: 14px;
    height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    margin: 0px 5px;
    cursor: help;

    &.success {
      background: #1fa856;
    }

    &.error {
      background: #e32222;
    }

    &.pending {
      background: #ff9061;
    }

    &.inactive {
      background: #a1a1a1;
    }
  }

  mat-icon {
    height: 10px;
    width: 10px;
    font-size: 10px;
    color: white;
  }
}

google-map {
  font-family: 'Inter' !important;
  .gm-style-iw {
    font-family: 'Inter' !important;
    padding: 0 !important;
    width: 344px;
    min-height: 268px !important;

    .gm-style-iw-d {
      overflow: hidden !important;
      max-height: 100% !important;
    }

    .app-button {
      border-radius: 0px 0px 4px 4px !important;
      width: 100%;
      border: none !important;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.86;
      letter-spacing: normal;
      text-transform: none;
    }

    .green {
      color: green;
    }
  
    .red {
      color: red;
    }
  }
  
  .gm-style-iw > button {
    display: none !important;
  }
}

.app-stepper {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #d9d9d9;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: var(--primaryColor);
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: var(--primaryColor);
  --mat-stepper-header-edit-state-icon-foreground-color: white;
  --mat-stepper-header-icon-background-color: #d9d9d9;
  --mat-stepper-container-color: transparent;
  --mat-stepper-line-color: #d9d9d9;

  --mat-stepper-header-hover-state-layer-color: transparent !important;
  --mat-stepper-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-optional-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-selected-state-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-error-state-label-text-color: #f44336;
  --mat-stepper-header-error-state-icon-foreground-color: #f44336;
  --mat-stepper-header-error-state-icon-background-color: transparent;

  .mat-horizontal-stepper-header {
    pointer-events: none !important;
  }

  .mat-step-icon {
    border: 3px solid #f0f0f0;
    width: 30px;
    height: 30px;
  }

  .mat-step-icon-selected {
    border: 3px solid var(--primaryColor);
    width: 30px;
    height: 30px;
  }

  .mat-step-icon-content {
    display: none;
  }

  .mat-step-icon-state-done {

    .mat-step-icon-content {
      display: flex;
    }
  }

  .mat-step-header {
    cursor: auto;
  }

  .mat-stepper-horizontal-line {
    border-top-width: 4px !important;
  }

  .mat-horizontal-stepper-header::after {
    border-top-width: 4px !important;
    width: calc(50% - 15px) !important;
  }

  .mat-horizontal-stepper-header::before {
    border-top-width: 4px !important;
    width: calc(50% - 15px) !important;
  }



  .mat-horizontal-stepper-header[aria-selected="true"]::before {
    --mat-stepper-line-color: var(--primaryColor);
  }

  .mat-horizontal-stepper-header[aria-selected="true"]::after {
    width: calc(50% - 15px) !important;
  }

  .mat-horizontal-stepper-header:has(div):has(.mat-step-icon-state-done) {
    &::before {
      --mat-stepper-line-color: var(--primaryColor);
    }
  }

  .mat-horizontal-stepper-header:has(div):has(.mat-step-icon-state-done)+.mat-stepper-horizontal-line {
    --mat-stepper-line-color: var(--primaryColor);
  }

  .mat-horizontal-stepper-header:has(div):has(.mat-step-icon-state-done) {
    &::after {
        --mat-stepper-line-color: var(--primaryColor);
    }
  }

  .mat-step-label {
    padding: 8px 0 0 0 !important;
  }

  .mat-step-text-label {
    .step-title {
      font-size: 12px;
      font-weight: bold;
      color: #d9d9d9;
    }

    .step-text {
      font-size: 12px;
      color: #d9d9d9;
      line-height: 1.2;
    }
  }

  .mat-step-label-active {
    .mat-step-text-label {
      .step-title {
        font-size: 12px;
        font-weight: bold;
        color: var(--primaryColor);
      }

      .step-text {
        font-size: 12px;
        color: black;
        line-height: 1.2;
      }
    }
  }

  &.completed {
    .mat-step-header:last-child {
      .mat-step-icon-selected {
        border: 3px solid #f0f0f0;
        background-color: var(--primaryColor);
        width: 30px;
        height: 30px;
        background-image: url('assets/done.png');
        background-size: 18px 18px;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }
}

.mat-horizontal-content-container {
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}



// ng2-pdf-viewer
.ng2-pdf-viewer-container{
    overflow-x: hidden !important;
}

.mat-calendar .selected-date {
  background-color: var(--primaryColor) !important;
  border-radius: 50% !important;
}
